/* Définition des variables du thème */
:root {
  --primary-font-family: Nunito, -apple-system, system-ui, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  --secondary-font-family: Shippori Mincho B1, -apple-system, system-ui,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

  --theme-bordures: #d5dadf; /*gris bleuté*/
  --theme-primary: #2c465d; /*bleu foncé*/
  --theme-on-primary: #ffffff; /*blanc*/
  --theme-secondary: #e95e53; /*rouge-pale*/
  --theme-on-secondary: #ffffff; /*blanc*/
  --theme-background: #fbfbfb; /*gris-clair*/
  --theme-on-background: #2c465d; /*bleu foncé*/
  --theme-background-secondary: #ffffff; /*blanc*/
  --theme-background-secondary-50: #ffffff80; /*blanc avec opacité à 50%*/
  --theme-background-secondary-75: #ffffffc0; /*blanc avec opacité à 75%*/
  --theme-surface: #ebe9d7; /*beige*/
  --theme-surface-50: #ebe9d780; /*beige avec opacité à 50*/
  --theme-on-surface: #2c465d; /*bleu foncé*/
  --theme-on-surface-title: #444a4a; /*noir*/
  --theme-surface-secondary: #d1e6e6; /*vert-bleu-clair*/
  --theme-on-surface-secondary: #2c465d; /*bleu foncé*/
  --theme-box-shadow-color: #00000029; /*blanc avec opacité à 16%*/
  --theme-background-modal: #2c465d66; /*bleu foncé avec opacité à 40%*/

  --page-margin-horizontal: 0rem;
  --content-width: 70%;

  --header-bandeau-height: 18.75rem;
  --header-search-bar-height: 7.5rem;

  /* z-index assurant (> 10XX) d'être par dessus les tuiles/icônes de OpenLayers */
  --carte-z-index: 1500;

  --largeur-maximal: 120rem;
}

html {
  font-size: 16px;
  height: 100%;
  scroll-padding-top: var(--header-search-bar-height);
}

/*
  On réduit la taille du texte pour les écrans de petite taille (exemple : 13") avec
  une large définition (exemple : 1920x1080 ou plus) et un zoom de l'écran supérieur à 100%.

  On se base ici sur le ratio de pixels pour déterminer si l'écran a été zoomé.
  Attention par contre, les smartphones et tablettes ont souvent un ratio de pixels au delà de 1
  mais ont une taille de texte correct. On ne doit pas diminuer la taille du texte dans ce cas là.
  C'est pour cela qu'on vérifie aussi la largeur de l'appareil pour ne pas les cibler.
*/
@media (min-width: 768px) and (min-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.25) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (min-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.5) {
  html {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  :root {
    --header-bandeau-height: 10em;
    --header-search-bar-height: 5.5em;
  }
}

body {
  height: 100%;
  margin: 0;
  background-color: var(--theme-background);
  color: var(--theme-on-background);
  font: normal normal normal 1rem var(--primary-font-family);
}

svg {
  fill: currentColor;
}

a {
  color: currentColor;
}

.bg-image {
  position: relative;
}

@media screen {
  .bg-image::after {
    width: 38rem;
    height: 38rem;
    content: '';
    position: absolute;
    background-color: var(--theme-surface);
    opacity: 0.5;
    /* stylelint-disable plugin/no-unsupported-browser-features */
    /* Sur chrome et edge c'est supporte avec le prefix -webkit- */
    -webkit-mask: var(--url-bg-image) no-repeat;
    mask-image: var(--url-bg-image);
    mask-repeat: no-repeat;
    /* stylelint-enable */
    right: 1rem;
    top: 2rem;
    z-index: -1000;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

#container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#content,
footer {
  width: min(100vw, var(--largeur-maximal));
  max-width: 100%;
  margin: 0 auto;
}

#content {
  min-height: 100%;
  flex-grow: 1;
}

footer {
  background-color: var(--theme-primary);
  font: normal normal normal 1rem / 1.5rem var(--primary-font-family);
  color: var(--theme-on-primary);
  padding: 3em 3em 1em 3em;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

footer .footer-body {
  display: flex;
  gap: 3rem;
}

footer .infos-region {
  flex: 3;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

footer .title {
  font: normal normal 500 1.375rem / 2rem var(--secondary-font-family);
  letter-spacing: 0;
  margin-bottom: 1rem;
}

footer .infos-region .title {
  /* On retire 0.5rem de .title qui seront compensés avec le row-gap de .infos-region */
  margin-bottom: 0.5rem;
}

footer .bottom-link-group .title,
footer .region {
  text-transform: uppercase;
}

footer .link-with-icon {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

footer .adresse {
  white-space: pre-line;
}

footer .copyright {
  border-top: var(--theme-on-primary) solid 0.0625rem;
  grid-area: copyright;
  text-align: center;
  padding: 0 10em;
  font: normal normal normal 1rem / 1.5rem var(--primary-font-family);
}

footer .bottom-link-group {
  flex: 2;
}

footer .bottom-link-group .title {
  font-size: 1.5em;
}

footer .bottom-link-group ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

footer a {
  color: var(--theme-on-primary);
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

footer .mail,
footer .aide,
footer .mention-legales,
footer .politique-confidentialite {
  font-weight: bold;
}

footer svg {
  width: 1.5rem;
}

@media (max-width: 1279px) {
  footer .footer-body {
    display: flex;
    flex-direction: column;
  }

  footer .copyright {
    padding: 0 var(--page-margin-horizontal);
  }
}

/* Pour les images et le bouton flottant pour affichier titre et lien */
.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-container img,
.image-container svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-container .popup {
  display: none;
  z-index: 500;
  max-width: 50%;
  min-width: 15rem;
  position: absolute;
  bottom: 1em;
  right: 1em;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1rem;
}

.image-container .popup h3 {
  font: normal normal bold 1.125rem / 1.625rem var(--secondary-font-family);
  margin: 0;
}

@media (max-width: 1279px) {
  .image-container .popup {
    max-width: 80vw;
  }
}

.image-container .popup-wrap .popup:hover {
  display: block;
}

.image-container .popup-wrap .btn-image:hover + .popup {
  display: block;
}

.image-container .popup-wrap ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.image-container .btn-image {
  --btn-image-background-color: var(--theme-primary);
  --btn-image-color: var(--theme-on-primary);

  z-index: 510;
  position: absolute;
  bottom: 1.5em;
  right: 1.5em;
  border: none;
  cursor: pointer;
  padding: 1.1em;
  border-radius: 3rem;
  background-color: var(--btn-image-background-color);
  color: var(--btn-image-color);
}

.image-container .btn-image:hover {
  background-color: var(--btn-image-color);
  color: var(--btn-image-background-color);
}

.image-container .btn-image svg {
  width: 2rem;
}

/* Dans le cas ou il n'y a pas de titre dans la popup*/
.image-container .popup div:first-child {
  padding-top: 1em;
}
.image-container .popup a {
  font: normal normal bold 1rem / 1.375rem var(--primary-font-family);
  color: var(--theme-primary);
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0.5rem 4.375rem 0 0;
}
.image-container .popup a:hover {
  text-decoration: underline;
}
.image-container .popup a .icon-lien-dossier {
  margin-left: 0.5em;
}
.image-container .popup.show {
  display: block;
}

.image-container .popup svg {
  width: 2rem;
  padding-left: 0.5em;
}

.image-indispo-filigrane {
  opacity: 0.3;
}

.illustration-probleme-diffusion {
  position: absolute;
  color: var(--theme-on-background);
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.illustration-probleme-diffusion .texte {
  background-color: var(--theme-primary);
  color: var(--theme-on-primary);
  padding: 1em;
  box-shadow: 0.3rem 0.3rem 0.3rem #00000080;
}

.image-indispo {
  position: relative;
}

details > summary {
  display: flex;
  align-items: center;
  list-style: none;
  cursor: pointer;
}
summary::-webkit-details-marker {
  display: none;
}

summary::after,
details[open] summary::after {
  width: 2rem;
  height: 2rem;
  background-color: currentColor;
  /* stylelint-disable plugin/no-unsupported-browser-features */
  /* Sur chrome et edge c'est supporté avec le prefix -webkit- */
  mask-repeat: no-repeat;
  -webkit-mask: var(--url-icon-summary-close) no-repeat;
  mask-image: var(--url-icon-summary-close);
  /* stylelint-enable */
  content: '';
}

details[open] summary::after {
  /* stylelint-disable plugin/no-unsupported-browser-features */
  /* Sur chrome et edge c'est supporté avec le prefix -webkit- */
  -webkit-mask-image: var(--url-icon-summary-open);
  mask-image: var(--url-icon-summary-open);
  /* stylelint-enable */
}

.lien-auteur {
  display: inline-block;
  position: relative;
}
.lien-auteur-popup {
  display: none;
  position: absolute;
  padding: 2rem 0 0 0;
  z-index: 10;
  top: calc(100% - 2rem);
}
.lien-auteur-popup-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 1rem 2rem 1rem;
  font: normal normal normal 1rem / 1.25rem var(--primary-font-family);
  background-color: var(--theme-background-secondary);
  color: var(--theme-primary);
  border-radius: 0.625rem;
  box-shadow: 0 0.2rem 0.375rem var(--theme-box-shadow-color);
  min-width: 19rem;
  max-width: 31rem;
}
.lien-auteur-popup-title {
  font: normal normal bold 1rem / 1.375rem var(--primary-font-family);
}
.lien-auteur-popup:hover,
.lien-auteur-link:hover ~ .lien-auteur-popup {
  display: block;
}

.lien-auteur a.lien-auteur-link:not(:hover):not(:active) {
  text-decoration: underline;
}
.list-auteur {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media print {
  @page {
    margin: 1cm;
    size: A4;
  }

  body {
    background-color: initial;
  }

  footer {
    display: none;
  }
}
